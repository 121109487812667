<template>
  <div class="text-center relative h-10">
    <transition name="fade" mode="out-in">
      <div class="text-center" v-if="loading" key="spin">
        <Spinner />
      </div>
      <div v-else key="done">
        <p class="text-black text-sm text-left">Payment card</p>
        <div
          class="drop-shadow border rounded-full border-lightPurple p-2 flex justify-between items-center"
          :class="[width]"
        >
          <div class="flex items-center">
            <svg
              v-if="value.cardType === 'MASTERCARD'"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="19"
              fill="none"
              class="mr-2"
            >
              <path
                d="M19.766 2.032h-8.413v14.936h8.413V2.032Z"
                fill="#FF5F00"
              />
              <path
                d="M11.886 9.5c0-3.035 1.442-5.726 3.659-7.468A9.63 9.63 0 0 0 9.615 0C4.3 0 0 4.249 0 9.5S4.3 19 9.615 19a9.63 9.63 0 0 0 5.93-2.032 9.445 9.445 0 0 1-3.66-7.468Z"
                fill="#EB001B"
              />
              <path
                d="M31.117 9.5c0 5.251-4.3 9.5-9.615 9.5a9.63 9.63 0 0 1-5.93-2.032 9.405 9.405 0 0 0 3.66-7.468c0-3.035-1.443-5.726-3.66-7.468A9.63 9.63 0 0 1 21.502 0c5.315 0 9.615 4.275 9.615 9.5Z"
                fill="#F79E1B"
              />
            </svg>
            <img
              v-else-if="value.cardType === 'VISA'"
              class="w-8 h-8 mr-2"
              src="@/assets/icons/visa.png"
              alt=""
            />
            <img
              v-else-if="value.cardType === 'VERVE'"
              class="w-8 mr-2"
              src="@/assets/icons/verve.png"
              alt=""
            />
            <p class="text-left">
              {{ value.maskedPan }}
            </p>
          </div>
          <AddCard v-if="cardData.length === 0" @close="handleCloseCard" />
          <SecondaryButton
            v-else
            class="button"
            text="Change Card"
            p="py-2 px-3"
            @click="handleOptions"
          />

          <transition name="slideUp">
            <div
              v-click-outside="handleOptionsOutside"
              class="options-card absolute scrollbar w-full bg-white p-3"
              v-if="showOptions"
            >
              <p class="text-center semibold" v-if="cardData.length === 0">
                You have not added any card
              </p>

              <div
                class="py-2 w-full"
                v-for="option in cardData"
                :key="option.id"
                v-else
              >
                <div
                  class="flex items-center justify-between cursor-pointer"
                  @click.stop="chooseOption(option)"
                >
                  <div class="flex items-center">
                    <svg
                      v-if="option.cardType === 'MASTERCARD'"
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="19"
                      fill="none"
                      class="mr-2"
                    >
                      <path
                        d="M19.766 2.032h-8.413v14.936h8.413V2.032Z"
                        fill="#FF5F00"
                      />
                      <path
                        d="M11.886 9.5c0-3.035 1.442-5.726 3.659-7.468A9.63 9.63 0 0 0 9.615 0C4.3 0 0 4.249 0 9.5S4.3 19 9.615 19a9.63 9.63 0 0 0 5.93-2.032 9.445 9.445 0 0 1-3.66-7.468Z"
                        fill="#EB001B"
                      />
                      <path
                        d="M31.117 9.5c0 5.251-4.3 9.5-9.615 9.5a9.63 9.63 0 0 1-5.93-2.032 9.405 9.405 0 0 0 3.66-7.468c0-3.035-1.443-5.726-3.66-7.468A9.63 9.63 0 0 1 21.502 0c5.315 0 9.615 4.275 9.615 9.5Z"
                        fill="#F79E1B"
                      />
                    </svg>
                    <img
                      v-else-if="option.cardType === 'VISA'"
                      class="w-8 mr-2"
                      src="@/assets/icons/visa.png"
                      alt=""
                    />
                    <img
                      v-if="option.cardType === 'VERVE'"
                      class="w-8 mr-2"
                      src="@/assets/icons/verve.png"
                      alt=""
                    />
                    {{ option.maskedPan }}
                  </div>

                  <img
                    v-show="value.id === option.id"
                    src="https://res.cloudinary.com/zillaafrica/image/upload/v1630797354/customer/Group_642_kmimow.svg"
                    alt=""
                    class="eligibile-icon w-4"
                  />
                </div>
                <Hr />
              </div>
              <AddCard margin="mx-auto" @close="handleCloseCard" />
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Hr from "@/UI/Hr";
import { SecondaryButton } from "@/UI/Button";
import AnimateHeight from "@/UI/AnimateHeight";
import Spinner from "@/UI/Spinner";
export default {
  components: {
    Hr,
    SecondaryButton,
    AnimateHeight,
    Spinner,
    AddCard: () => import("@/components/Verification/AddCard"),
  },
  props: {
    width: {
      type: String,
      default: "w-auto",
    },
  },
  data: () => ({
    showOptions: false,
    value: {
      cardType: "",
      maskedPan: "",
      id: "",
    },
    loading: false,
  }),
  computed: {
    ...mapState({
      cardList: (state) => state.dashboard.userCards,
    }),
    cardData() {
      let list = this.cardList;
      if(this.cardList.length){
        const primaryCard = this.cardList.filter((card) => card.primary);
        const index = this.cardList.findIndex((card) => card.primary);
        list.splice(index, 1);
        list.unshift(primaryCard[0]);
      }
      return list;
    },
  },
  beforeMount() {
    if(this.cardList.length === 0){
      this.getUserCards();
    }else{
      this.value = this.cardData[0];
      this.$emit("input", this.cardData[0].id);
    }
  },
  methods: {
    ...mapActions("dashboard", ["getCards"]),
    ...mapActions("notification", ["showAlert"]),
    chooseOption(value) {
      this.value = value;
      this.showOptions = false;
      this.$emit("input", value.id);
    },

    clickTarget(e) {
      if (e.target.className.includes("select-container")) {
        this.showOptions = false;
      }
    },

    handleOptionsOutside() {
      this.showOptions = false;
    },

    getUserCards(value) {
      this.loading = true;
      this.getCards()
        .then(() => {
          if (value) {
            this.chooseOption(value);
          } else if (this.cardData[0]) {
            this.value = this.cardData[0];
            this.$emit("input", this.cardData[0].id);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    handleOptions() {
      this.showOptions = !this.showOptions;
    },

    handleCloseCard(data) {
      if (data) {
        this.getUserCards(data.maskedCard);
      }
    },
  },
};
</script>
<style scoped>
@import "~@/assets/styles/animation.css";

.line {
  /* height: 2rem; */
  border-bottom: 1px solid black;
}
/* .button {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  } */
.options-card {
  box-shadow: 0px 6.65584px 39.9351px rgba(128, 70, 251, 0.2);
  border-radius: 10px;
  z-index: 2;
  margin-top: 0.3rem;
  max-height: 15rem;
  overflow-y: auto;
  left: 0;
  bottom: 0;
}
/* .options::-webkit-scrollbar {
    width: 5px !important;
  }
  .options::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #d3d3d3;
    border-radius: 10px;
  }
  .options::-webkit-scrollbar-thumb {
    background: #c0c0c0;
    border-radius: 10px;
  } */
.lds-dual-ring {
  display: inline-block;
  width: 20px;
  height: 20px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  margin: 8px;
  border-radius: 50%;
  border: 2px solid black;
  border-color: black transparent black transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
